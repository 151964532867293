import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const slider = document.querySelector('.slider-header');

  if (slider) {
    const slides = slider.querySelectorAll('.swiper-slide');
    const args = {
      slidesPerView: 1,
      loop: false,
      watchOverflow: true,
      pagination: {
        el: slider.querySelector('.slider-header__carousel__pagination'),
        clickable: true,
      },
      autoplay: {
        delay: 6000,
      },
    };

    if (1 < slides.length) {
      args.loop = true;
      args.watchOverflow = false;
    }

    new Swiper(slider.querySelector('.slider-header__carousel'), args);
  }
});
